exports.components = {
  "component---src-sites-type-ak-royal-poland-templates-add-domain-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-add-domain-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-category-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/Category.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-category-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-company-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/Company.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-company-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-contact-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-contact-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-home-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-home-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-not-found-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-not-found-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-search-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-search-tsx" */),
  "component---src-sites-type-ak-royal-poland-templates-terms-tsx": () => import("./../../../src/sites/typeAk/royal-poland/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-ak-royal-poland-templates-terms-tsx" */)
}

